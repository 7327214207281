//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import EventsTable from "@/views/reportes_eventos";
import SamplesTable from "@/views/reportes_muestras";


export default {
  components: {
    EventsTable,
    SamplesTable
  },
  data: function () {
    return {
      total_records: 0,
      serverItemsLength:100,
      show: true,
      show_wait_download: false,
      show_events: false,
      show_samples: false,
      report_data:null,
      type_of_reports: [{name:'Eventos',value:'event'},
                        {name:'Muestras',value:'sample'}
      ],
      trucks : [],
    
      sample_sort_by: 'stamp',
      sample_sort_desc: true,
      event_sort_by: 'stamp',
      event_sort_desc: true,
            
      show_help: false,
      
      menu_picker_ini:false,
      menu_picker_end:false,
      filter: {
        report_type: 'event',
        truckid:'ALL',
        date_picker_ini: this.$moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
        date_picker_end: this.$moment(new Date()).format('YYYY-MM-DD'),

      },
      users: [
        { firstname : '2022-01-10 08:00:00', lastname:'10', rut:'Quadra 77', email: '10', mobilephone: '10', perfil: '10', action:'10' },
        { firstname : '2022-01-10 08:00:30', lastname:'10', rut:'Quadra 77', email: '10', mobilephone: '10', perfil: '10', action:'10' },
        { firstname : '2022-01-10 08:01:00', lastname:'10', rut:'Quadra 77', email: '10', mobilephone: '10', perfil: '10', action:'10' },
        { firstname : '2022-01-10 08:01:00', lastname:'10', rut:'Quadra 77', email: '10', mobilephone: '10', perfil: '10', action:'10' },
      ],
      headers: [
        {
          text: 'Camión',
          align: 'left',
          sortable: false,
          value: 'rut',
        },

        { text: 'Fecha y hora', value: 'firstname' },
        { text: 'EngineOilLevel', value: 'lastname' },
        { text: 'RPM', value: 'email' },
        { text: 'EngineOilLevel', value: 'mobilephone' },
        { text: 'EngineTotalHours', value: 'perfil', align:'center' ,sortable: false },
        { text: 'FuelLevel', value: 'action', align:'center' ,sortable: false }
    
      ],
    }
  },
  created() {
    console.log('create -> [Reportes]');
    this.$bus.$on('reportes', this.set);
    // this.$util.log(this.$store.state.trucks);
    // Listado de camiones del filtro, agrega opcionde TODOS
    this.trucks = this.$util.clone(this.$store.state.trucks);
    this.trucks.unshift({name:'--TODOS---',truckid:'ALL'});
    this.getReport('');

  },
  
  destroyed() {
      this.$bus.$off('reportes', this.set);  
  },
  
  computed: {

  },
  methods: {

    set(arg) {
      this.$util.log(arg);
      
      if (arg.report=='sample') {
        this.sample_sort_by = arg.sortBy;
        this.sample_sort_desc = arg.sortDesc;
      }
      else {
          this.event_sort_by = arg.sortBy;
          this.event_sort_desc = arg.sortDesc;        
      }
      
      
      this.getReport();
    },
    
    
    getReport(download) {

      this.$util.log('getReport-->'+this.filter.report_type);
      this.$store.state.ts.loader.show = true;

      this.$axios.all([
        this.$axios.get('/api/report'+this.filter.report_type+'/', 
          { params: {
              download: download,
              truckid:  this.filter.truckid,
              stamp_end: this.filter.date_picker_end,
              stamp_ini: this.filter.date_picker_ini,
              sort_by: (this.filter.report_type=='sample')?this.sample_sort_by : this.event_sort_by,
              sort_desc: (this.filter.report_type=='sample')?this.sample_sort_desc : this.event_sort_desc,
          } } ),
      ])
      .then(this.$axios.spread((report_resp)  =>{
        console.log(report_resp.data);
      
        
        
        if (download=='csv') {
            /*
            const url = window.URL.createObjectURL(new Blob([report_resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'c.csv');
            document.body.appendChild(link);
            link.click();
            */
        }
        if (this.filter.report_type=='event') {
          this.show_events= true;
          this.show_samples= false;
          this.report_data= report_resp.data.events;
          this.total_records= report_resp.data.total_records;
        }
        if (this.filter.report_type=='sample') {
          this.show_events= false;
          this.show_samples= true; 
          this.report_data= report_resp.data.samples;
          this.total_records= report_resp.data.total_records;            
        }
        
        this.$store.state.ts.loader.show = false;
        
      }))
      .catch((report_err) => {
        console.log(report_err);
      });
    },


    click_descarga_reporte_1: function() {
      this.show_wait = true;
    },

    click_descarga_reporte_2: function() {
      this.show_wait = true;
    }
  }
};
