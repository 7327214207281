var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1 css_config_table",style:({width:'100%'}),attrs:{"dense":"","must-sort":"","sort-by":"stamp","footer-props":{
          itemsPerPageOptions: [],
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus'
        },"hide-default-footer":true,"items-per-page":20000000,"options":_vm.pagination_options,"server-items-length":_vm.serverItemsLength,"headers":_vm.headers,"items":_vm.data},on:{"update:options":function($event){_vm.pagination_options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.length)+" registros de un total de "+_vm._s(_vm.totalRecords)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-spacer')],1)]},proxy:true},{key:"item.deviation_level",fn:function(ref){
        var item = ref.item;
return [(item.deviation_level=='h')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white","min-width":"200"}},[_vm._v("Alto")]):_vm._e(),(item.deviation_level=='m')?_c('v-chip',{staticClass:"ma-2",attrs:{"min-width":"200","color":"yellow","text-color":"white"}},[_vm._v("Medio")]):_vm._e(),(item.deviation_level=='n')?_c('v-chip',{staticClass:"ma-2",attrs:{"min-width":"200","color":"green","text-color":"white"}},[_vm._v("Normal")]):_vm._e()]}}],null,false,3664110413)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }