//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  
  name:"EventsTable",
  props: ['show','data','serverItemsLength','totalRecords'],
  data: function () {
    return {
      componentKey:0,
      pagination_options: {},
      headers: [
        {
          text: 'STAMP',
          align: 'center',
          sortable: true,
          value: 'stamp',
          width:'20%',
        },
        { text: 'Camion', value: 'truck.name', width:'15%',sortable: true },
        { text: 'Evento', value: 'recipe.name',width:'15%',sortable: true },
        { text: 'Desviación', value: 'level',width:'10%',sortable: true },
        { text: 'Descripción', value: 'description',width:'40%', },
        //{ text: 'Valor', value: 'deviation_value', width:'10%' },
      ],
    }
  },
  created() {
    console.log('create -> [Reportes Events]');
    console.log(this.data);
  },
  computed: {

  },
  methods: {

  },
  watch: {

      pagination_options: {
        handler (e) {
            console.log(e.sortBy[0]);
            console.log(e.sortDesc[0]);
            this.$bus.$emit('reportes',{ report:'event',sortBy:e.sortBy[0], sortDesc:e.sortDesc[0] });
        },
        deep: true,
      },

  }
  
};
