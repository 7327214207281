//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name:"SamplesTable",
  props: ['show','data','serverItemsLength','totalRecords'],
  data: function () {
    return {
      componentKey:0,
      pagination_options: {},
      headers: [

        { text: 'STAMP', value: 'stamp', sortable: true },
        { text: 'Camion', value: 'truck_name', sortable: true  },
        { text: 'Velocidad', value: 'Velocidad', sortable: true },
        { text: 'Altitud', value: 'Altitud', sortable: true },
        { text: 'EngineOilLevel', value: 'EngineOilLevel', sortable: true },
        { text: 'RPM', value: 'RPM', sortable: true },
        //{ text: 'EngineTotalHours', value: 'EngineTotalHours', align:'center' ,sortable: false },
        { text: 'FuelLevel', value: 'FuelLevel', align:'center' ,sortable: true }
    
      ],

    }
  },
  created() {
    console.log('create -> [Reportes Samples]');
    console.log(this.data);

  },
  computed: {

  },
  
  methods: {
  },
  watch: {

    pagination_options: {
      handler (e) {
          console.log(e.sortBy[0]);
          console.log(e.sortDesc[0]);
          this.$bus.$emit('reportes',{ report:'sample',sortBy:e.sortBy[0], sortDesc:e.sortDesc[0] });
      },
      deep: true,
    },

  }
};
